




























































import { Vue, Component } from "vue-property-decorator";
import { ComponentOptions } from "vue/types/options";

import { take } from "rxjs/operators";

import CityDarkBGComponent from "@/components/core/backgrounds/CityDarkBG.vue";
import MediaCarouselComponent from "@/components/media/carousel/MediaCarousel.vue";
import SlideGroupComponent from "@/components/media/slide-group/SlideGroup.vue";
import CarouselPageSkeletonLoader from "@/components/media/carousel/CarouselPageSkeletonLoader.vue";

import { WebinarService, DCSLanguageService, MetaService } from "@/services";
import { Webinar } from "@/types/webinar.interface";

import VueMarkdown from "vue-markdown";

const metaInfo: ComponentOptions<Vue>['metaInfo'] = {
  title: 'Building Science Webinars on Demand',
  meta: [
    {
      vmid: 'description',
      name: 'description',
      content: 'Watch webinar video replays about innovation, performance, and sustainability in Dow Building Science products.',
    },
  ],
};

export const metaInfoMixin = {
  metaInfo,
};


@Component({
  mixins: [metaInfoMixin],
  components: {
    CarouselPageSkeletonLoader,
    CityDarkBGComponent,
    MediaCarouselComponent,
    SlideGroupComponent,
    VueMarkdown
  },
})
export default class Webinars extends Vue {
  public DCSLanguageService = new DCSLanguageService();

  private webinarService = new WebinarService();
  private metaService = new MetaService();

  featuredWebinar: Webinar | null | undefined = null;
  webinarVideos: Webinar[] = [];

  hasNoWebinars = false;
  isLoading = true;

  webinarSelected(index: number): void {
    this.featuredWebinar = this.webinarVideos[index];
  }

  private created(): void {
      this.metaService.updateMetaDescription('Watch webinar video replays about innovation, performance, and sustainability in Dow Building Science products.');
      this.metaService.updateMetaTitle('Building Science Webinars on Demand');

    this.webinarService
      .getWebinarVideoList()
      .pipe(take(1))
      .subscribe((webinars: Webinar[]) => {
        this.webinarVideos = webinars;
        this.featuredWebinar = webinars[0] ?? null;
        if(this.webinarVideos.length==0)
          this.hasNoWebinars=true;
        this.isLoading = false;
      });
  }
}
