var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carousel" },
    [
      _c("div", { staticClass: "title" }, [
        _c("h1", { staticClass: "white--text" }, [
          _vm._v(
            " " +
              _vm._s(_vm.DCSLanguageService.translate("WebinarsAndVideos")) +
              " "
          )
        ])
      ]),
      _vm.featuredWebinar
        ? _c("div", { staticClass: "text-media-container" }, [
            _c("div", { staticClass: "text-container" }, [
              _c("h2", [_vm._v(" " + _vm._s(_vm.featuredWebinar.title) + " ")]),
              _c(
                "p",
                [
                  _c("vue-markdown", {
                    attrs: { source: _vm.featuredWebinar.description }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "media-container" },
              [
                _c("MediaCarouselComponent", {
                  attrs: { media: _vm.featuredWebinar.media }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "slide-group-container" },
        [
          _vm.webinarVideos.length
            ? _c("SlideGroupComponent", {
                attrs: { "carousel-items": _vm.webinarVideos },
                on: { "item-select": _vm.webinarSelected }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.hasNoWebinars
        ? _c("div", { staticClass: "display-image" }, [
            _c("img", {
              staticClass: "display-image",
              attrs: { src: require("@/assets/no_webinars.svg") }
            }),
            _c("h2", [
              _vm._v(
                " " +
                  _vm._s(_vm.DCSLanguageService.translate("NoWebinars")) +
                  " "
              )
            ])
          ])
        : _vm._e(),
      _vm.isLoading ? _c("CarouselPageSkeletonLoader") : _vm._e(),
      _c("CityDarkBGComponent", {
        attrs: { "modifier-class": "dark-bg--fixed" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }